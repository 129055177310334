import React, { useState } from 'react';
import { Tabs } from '@syneto/compass-react';
import Renewals from './adminSettingsRenewalsTab';
import Serenity from './adminSettingsSerenityTab';
import RAS from './adminSettingsRASTab';
import NPS from './adminSettingsNPSTab';
import VirtualAppliance from './adminSettingsVirtualApplianceTab';
import UpgradeToOs from './adminSettingsUpgradeToOsTab';

const AdminSettingsForm = (props) => {
	const [activeKey, setActiveKey] = useState('renewals');

	return (
		<div id="settings" className="p-4 bg-white">
			<div className="col-12">
				<Tabs activeKey={activeKey} onSelect={(tab) => { return setActiveKey(tab); }}>
					<Tabs.Tab eventKey="renewals" title="Renewals" className="qa-renewals">
						<Renewals activeKey={activeKey} />
					</Tabs.Tab>
					<Tabs.Tab eventKey="serenity" title="Serenity" className="qa-serenity">
						<Serenity activeKey={activeKey} />
					</Tabs.Tab>
					<Tabs.Tab eventKey="ras" title="RAS" className="qa-ras">
						<RAS activeKey={activeKey} />
					</Tabs.Tab>
					<Tabs.Tab eventKey="nps" title="NPS" className="qa-nps">
						<NPS activeKey={activeKey} />
					</Tabs.Tab>
					<Tabs.Tab eventKey="virtualAppliance" title="Virtual Appliance" className="qa-virtual-appliance">
						<VirtualAppliance activeKey={activeKey} />
					</Tabs.Tab>
					<Tabs.Tab eventKey="upgradeToOs5" title="Upgrade to OS5" className="qa-upgrade">
						<UpgradeToOs activeKey={activeKey} />
					</Tabs.Tab>
				</Tabs>
			</div>
		</div>
	);
};

export default AdminSettingsForm;
