import { backEndAxios, restAxios } from './httpClient';

export const getSettings = () => {
	return backEndAxios.get('/centralBackend/v2/settings/getSettings');
};

export const getUpgradeToOsSettings = () => {
	return restAxios.get('/system/settings/upgrade-to-os5');
};

export const patchUpgradeToOsSettings = (payload) => {
	return restAxios.patch('/system/settings/upgrade-to-os5', payload);
};
